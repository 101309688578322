<template>
  <div v-if="specialNote" class="product__special-note">
    <InfoIcon class="product__special-note__icon" />
    <span class="product__special-note__text" :data-id="specialNoteId">{{ specialNote }}</span>
  </div>
</template>

<script setup lang="ts">
import InfoIcon from '~/assets/icons/info.svg'

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  hideSizeSpecialNote: {
    type: Boolean,
    default: false,
  },
})

const SIZE_SPECIAL_NOTE_ID = 2067

const productStore = useProductStore()
const specialNoteId = ref(props.product?.special_note || 0)

//MYJE-5665
if (props.hideSizeSpecialNote && specialNoteId.value === SIZE_SPECIAL_NOTE_ID) specialNoteId.value = 0

const specialNote = productStore.getAttributeById(specialNoteId.value.toString(), 'special_note')
</script>

<style lang="scss">
.product__special-note {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0 0 var(--spacer-xl);
  padding: 0 var(--spacer-xs);

  &__icon {
    margin-right: var(--spacer-sm);
    flex: 0 0 24px;
  }

  &__text {
    flex: 1;
    font-size: var(--font-size-sm);
    line-height: 1.4286;
  }
}
</style>
