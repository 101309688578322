<template>
  <div>
    <h4 v-if="soldOutForm.type === 'soldOut'">
      {{ $t('Sold out form title') }}
    </h4>
    <p>
      {{ $t(soldOutForm.type === 'soldOut' ? 'Sold out form description' : 'Coming soon form description') }}
    </p>

    <Form v-slot="{ meta }" :validation-schema="validationSchema" @submit="submit">
      <FormField name="email" :label="$t('Enter your email address')" type="email" />
      <FormCheckbox name="newsletter_signup" value="newsletter_signup" :label="$t('Subscribe to newsletter')" />

      <div class="actions modal__button">
        <SfButton type="submit" :disabled="!meta.valid">
          <SfLoader v-if="loading" class="loader" :loading="loading" />
          <CheckmarkIcon v-else-if="done" />
          <span v-else>{{ $t(soldOutForm.type === 'soldOut' ? 'Keep me updated' : 'Notify me on release') }}</span>
        </SfButton>
        <slot />
      </div>
    </Form>
  </div>
</template>

<script setup lang="ts">
import useExponeaApi from '~/composables/useExponeaApi'
import useExponeaConstants from '~/utils/constants/exponea'
import CheckmarkIcon from '~/assets/icons/checkmark-alt.svg'

const logger = useAppLogger("SoldOutForm")

const emit = defineEmits(['toggleSoldOutForm'])

const validationSchema = {
  email: 'required|email',
  newsletter_signup: '',
}

const { soldOutForm } = storeToRefs(useUiState())

const { SIGNUP_SOURCE_SOLD_OUT_REGISTER, SIGNUP_SOURCE_COMING_SOON_REGISTER } = useExponeaConstants()
const signupSource = computed(() =>
  soldOutForm.value.type === 'soldOut' ? SIGNUP_SOURCE_SOLD_OUT_REGISTER : SIGNUP_SOURCE_COMING_SOON_REGISTER,
)
const { post } = useExponeaApi()
const loading = ref(false)
const done = ref(false)

const submit = async (values) => {
  const { email, newsletter_signup } = values

  const body = {
    customer_ids: { registered: email },
    properties: {
      action: 'new',
      productId: soldOutForm.value.productId, // Magento product ID
      status: 'registered',
      signup_source: signupSource.value,
      optionLabel: soldOutForm.value.optionLabel,
      email,
    },
    event_type: signupSource.value,
  }

  const newsletterBody = {
    customer_ids: { registered: email },
    properties: {
      action: 'new',
      email,
      signup_source: signupSource.value,
      consent_list: [
        {
          action: 'accept',
          category: 'newsletter',
          valid_until: 'unlimited',
        },
        {
          action: 'accept',
          category: 'personalised_email',
          valid_until: 'unlimited',
        },
      ],
    },
    event_type: 'double_opt_in',
  }

  loading.value = true

  try {
    await post('/customers/events', body, 'track')
    if (newsletter_signup) {
      await post('/customers/events', newsletterBody, 'track')
    }
    pushEventToGa()
  } catch (err) {
    logger.error(err)
  } finally {
    loading.value = false
    done.value = true

    window.setTimeout(() => {
      emit('toggleSoldOutForm')
      done.value = false
    }, 1500)
  }
}

const pushEventToGa = () => {
  window.dataLayer?.push({
    event: 'GAEvent',
    eventCategory: 'Marketing Automation',
    eventAction: 'Contact Signup',
    eventLabel: signupSource,
    eventValue: undefined,
  })
}
</script>

<style lang="scss" scoped>
form {
  margin-top: var(--spacer-md);

  .actions {
    display: flex;
  }
}

.sf-button {
  width: 100%;
}

.sf-loader__overlay {
  background: none;
}

.modal__button {
  margin-top: var(--spacer-md);
}
</style>
