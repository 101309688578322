import type { BundleProduct } from '@vue-storefront/magento-types'
import productGetters from './productGetters'

export const bundleProductInitialSelector = (bundles: BundleProduct['items']) =>
  Array.isArray(bundles)
    ? bundles.reduce((acc, curr) => {
        const defaultValue = curr.options.find((o) => o.is_default)

        if (defaultValue) {
          acc[curr.uid] = {
            uid: defaultValue.uid,
            quantity: defaultValue.quantity || 1,
            // @ts-ignore
            price: productGetters.getPrice(defaultValue.product).regular,
          }
        }

        return acc
      }, {})
    : {}
