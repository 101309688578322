<template>
  <div v-if="product" class="product__actions">
    <slot>
      <SfNotification
        :visible="errorNoVariantSelected && !canAddProductToCart"
        :message="
          $t('Please select {names}', {
            names: productNames,
          })
        "
        :persistent="false"
      />
      <template v-if="product.stock_status === 'OUT_OF_STOCK'">
        <ProductSoldOutForm>
          <ProductAddToWishList
            class="form-button product__add-to-wishlist sf-button--outline product__add-to-wishlist-button"
            type="button"
            :product="product"
          />
        </ProductSoldOutForm>
      </template>
      <template v-else-if="product.__typename === 'GroupedProduct'">
        <ProductGroupedProductSelector :can-add-to-cart="canAddProductToCart" @update-price="basePrice = $event">
          <template #add-to-cart-button="{ addToCart }">
            <SfButton
              class="color-primary sf-button grouped_items--add-to-cart"
              :disabled="!canAddProductToCart || isLoadingAddToCart || hasJustAddedToCart"
              :data-sku="selectedProduct.sku"
              :data-id="selectedProduct.id"
              data-testid="add-to-cart"
              @click="handleCustomAddToCart(addToCart)"
            >
              {{ addToCartButtonText }}
              <div v-if="isLoadingAddToCart" class="adding-to-cart">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </SfButton>
          </template>
        </ProductGroupedProductSelector>
      </template>

      <template v-else-if="product.__typename === 'BundleProduct'">
        <ProductBundleProductSelector :can-add-to-cart="canAddProductToCart" @update-price="basePrice = $event">
          <template #add-to-cart-button="{ addToCart }">
            <SfButton
              class="color-primary bundle_products--add-to-cart"
              :class="{ 'is-adding-to-cart--button': isLoadingAddToCart }"
              :disabled="!canAddProductToCart || isLoadingAddToCart || hasJustAddedToCart"
              :data-sku="selectedProduct.sku"
              :data-id="selectedProduct.id"
              data-testid="add-to-cart"
              @click="handleCustomAddToCart(addToCart)"
            >
              {{ addToCartButtonText }}
              <div v-if="isLoadingAddToCart" class="adding-to-cart">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </SfButton>
          </template>
        </ProductBundleProductSelector>
      </template>

      <div v-else class="product__add-to-cart">
        <SfButton
          class="sf-add-to-cart__button sf-button"
          :class="{
            'is-disabled--button': !canAddProductToCart || isLoadingAddToCart || hasJustAddedToCart,
          }"
          :data-sku="selectedProduct.sku"
          :data-id="selectedProduct.id"
          data-testid="add-to-cart"
          @click="addItemToCart()"
        >
          {{ addToCartButtonText }}
          <div v-if="isLoadingAddToCart" class="adding-to-cart">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </SfButton>
        <ProductBrands v-if="!isAtcModal" />
      </div>
    </slot>

    <slot name="additional-actions">
      <div v-if="product.stock_status !== 'OUT_OF_STOCK'" class="product__additional-actions">
        <ProductAddToWishList
          v-if="!isAtcModal"
          class="form-button product__add-to-wishlist sf-button--outline product__add-to-wishlist-button"
          :product="product"
          :selectedVariant="selectedProduct"
        />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { useCart } from '~/composables'
import { getProductOptionsNames, getSelectedProductVariant } from '~/utils/productGetters'
import stockStatusEnum from '~/enums/stockStatusEnum'
import type { Product } from '@vue-storefront/magento-types'

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    default: () => ({}),
  },
  productConfiguration: {
    type: Object,
    default: () => ({}),
  },
  isAtcModal: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['addedToCart'])

const { t } = useI18n()
const { addItem } = useCart()
const productStore = useProductStore()
const { setSoldOutFormData } = useUiState()
const { productCustomOptionsConfiguration, giftcardHolder, loading } = storeToRefs(productStore)
const { productCustomOptionsCanAddToCartHandler } = productStore
const errorNoVariantSelected = ref(false)

const productConfigurationCanAddToCart = computed(() => {
  const selectedOptions = Object.values(props.productConfiguration || {}).sort()
  if (!selectedOptions.length) return false
  return (
    props.product?.variants?.find((variant) =>
      variant.attributes.every((attribute) => selectedOptions.includes(attribute.uid)),
    )?.product.stock_status === 'IN_STOCK'
  )
})

const productCustomOptionsCanAddToCart = computed(() => productCustomOptionsCanAddToCartHandler(props.product.sku))

const canAddProductToCart = computed(() => {
  if (loading.value || !productCustomOptionsCanAddToCart.value) return false

  if (props.product?.__typename === 'ConfigurableProduct') {
    return productConfigurationCanAddToCart.value
  }

  return props.product?.stock_status === stockStatusEnum.inStock
})

const addToCartButtonText = computed(() => {
  if (hasJustAddedToCart.value) {
    return t('Added!')
  }
  if (isLoadingAddToCart.value) {
    return t('Adding')
  }
  return t('Add to cart')
})

const ADDED_TO_CART_MESSAGE_TIMEOUT_MS = 400
const isLoadingAddToCart = ref(false)
const hasJustAddedToCart = ref(false)
const addItemToCart = async () => {
  if (isLoadingAddToCart.value || hasJustAddedToCart.value) {
    return
  }

  if (!canAddProductToCart.value) {
    errorNoVariantSelected.value = true
    return setTimeout(() => (errorNoVariantSelected.value = false), 5000)
  }

  isLoadingAddToCart.value = true
  try {
    if (giftcardHolder.value) {
      // Await or you'll have an out of sync cart
      await addItem({ product: giftcardHolder.value, quantity: 1 })
    }

    await addItem({
      product: props.product,
      quantity: 1,
      productConfiguration: props.productConfiguration,
      productCustomOptionsConfiguration: productCustomOptionsConfiguration.value,
    })

    hasJustAddedToCart.value = true
    setTimeout(() => {
      hasJustAddedToCart.value = false
    }, ADDED_TO_CART_MESSAGE_TIMEOUT_MS)
  } catch (err) {
    // if we don't catch the error, it bubbles up all the way to a 500 error
  } finally {
    isLoadingAddToCart.value = false
  }

  emit('addedToCart')
}

const handleCustomAddToCart = async (callback) => {
  if (isLoadingAddToCart.value || hasJustAddedToCart.value) {
    return
  }
  isLoadingAddToCart.value = true
  try {
    await callback()
    hasJustAddedToCart.value = true
    setTimeout(() => {
      hasJustAddedToCart.value = false
    }, ADDED_TO_CART_MESSAGE_TIMEOUT_MS)
  } catch (err) {
    // if we don't catch the error, it bubbles up all the way to a 500 error
  } finally {
    isLoadingAddToCart.value = false
  }

  emit('addedToCart')
}

const productNames = getProductOptionsNames(props.product)
const selectedProduct = computed(() => getSelectedProductVariant(props.product, props.productConfiguration))

onMounted(() => {
  if (props.product?.stock_status === 'OUT_OF_STOCK') {
    setSoldOutFormData({
      productId: props.product?.pid,
      type: 'soldOut',
    })
  }
})

onUnmounted(() => {
  if (props.product?.stock_status === 'OUT_OF_STOCK')
    setSoldOutFormData({ productId: null, optionLabel: null, type: null, isModalVisible: false })
})

defineExpose({
  isLoadingAddToCart,
  addItemToCart,
})
</script>

<style lang="scss">
.product {
  &__actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacer-sm);

    @include for-mobile {
      padding: 0 var(--spacer-15);
    }
  }

  &__add-to-cart {
    flex-flow: column;
    margin: 0;
    flex-grow: 1;

    .sf-add-to-cart__button {
      --button-background: var(--cta-pink-color);
      --button-width: 100%;

      &:hover,
      &:focus {
        --button-background: var(--cta-pink-color);
      }

      &.is-disabled--button {
        --button-background: var(--primary-color);
        --button-color: var(--white-color);
        pointer-events: all; // To allow for "no variant selected error"
      }

      .adding-to-cart {
        span {
          height: 3px;
          width: 3px;
          background-color: var(--white-color);
          border-radius: 100%;
          display: inline-block;
          opacity: 0;
          animation: m 1.2s infinite;

          &:nth-child(2) {
            animation-delay: 0.3s;
          }

          &:nth-child(3) {
            animation-delay: 0.6s;
          }
        }
      }

      @keyframes m {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }

  &__add-to-wishlist {
    &.form-button {
      min-width: 46px;
      margin-left: var(--spacer-xs);

      @include for-desktop {
        min-width: 54px;
      }
    }
  }

  &__additional-actions {
    align-self: self-start;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 0 var(--spacer-xs);
  }

  .sf-notification {
    padding: 8px 8px 8px 16px;
    margin: 0 0 var(--spacer-sm);
    background-color: var(--red-background-color);
    color: var(--red-background-color) !important;

    .sf-icon,
    .sf-button {
      display: none;
    }

    &__message {
      color: black;
    }
  }
}
</style>
