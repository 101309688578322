<template>
  <div v-if="!loading">
    <ul class="grouped_items">
      <li v-for="(groupedItem, index) in groupedItems" :key="index" class="grouped_items--item">
        <nuxt-img
          :src="getMagentoImage(productGetters.getProductThumbnailImage(groupedItem.product))"
          :alt="productGetters.getName(groupedItem.product)"
          :width="60"
          :height="60"
        />
        <div>
          <p>{{ productGetters.getName(groupedItem.product) }}</p>
          <SfPrice
            :regular="$fc(productGetters.getPrice(groupedItem.product).regular)"
            :special="
              productGetters.getPrice(groupedItem.product).special &&
              $fc(productGetters.getPrice(groupedItem.product).special)
            "
          />
        </div>
        <SfQuantitySelector v-model="groupedItem.qty" :disabled="addingToCart || !canAddToCart" />
      </li>
    </ul>
    <slot name="add-to-cart-button" :addToCart="addToCart"></slot>
  </div>
</template>
<script setup lang="ts">
import productGetters from '~/utils/getters/magentoProductGetters'
import { useCart } from '~/composables/useCart'
import { useProduct } from '~/composables'

const props = defineProps({
  canAddToCart: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const emits = defineEmits(['update-price'])

const { product, loading } = storeToRefs(useProductStore())
const groupedItems = computed(() => productGetters.getGroupedProducts(product.value))

const { addItem } = useCart()
const addingToCart = ref(false)

const addToCart = async () => {
  try {
    addingToCart.value = true

    const groupedItemsFiltered = groupedItems.value.filter((p) => p.qty)
    if (groupedItemsFiltered.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const p of groupedItemsFiltered) {
        // eslint-disable-next-line no-await-in-loop
        await addItem({ product: p.product, quantity: p.qty })
      }
    }
    addingToCart.value = false
  } catch {
    addingToCart.value = false
  }
}

watch(
  groupedItems,
  (newValue) => {
    const getProductPrice = (p) => {
      const { regular, special } = productGetters.getPrice(p)

      return regular > special ? regular : special
    }

    const price = newValue.reduce((acc, curr) => curr.qty * getProductPrice(curr.product) + acc, 0)

    emit('update-price', price)
  },
  {
    deep: true,
  },
)

const { getMagentoImage } = useImage()
</script>
<style lang="scss">
.grouped_items {
  padding: 0 10px;

  &--item {
    display: grid;
    grid-template-columns: fit-content(70px) auto fit-content(100%);
    grid-template-rows: auto;
    align-items: center;
    justify-items: start;
    column-gap: var(--spacer-base);
    padding: var(--spacer-base) 0;
    border-bottom: 1px solid var(--c-text-muted);

    .sf-quantity-selector {
      justify-self: end;
    }
  }

  &--add-to-cart {
    width: 100%;
    margin-top: var(--spacer-lg);
  }
}
</style>
